import { defineMessages } from 'react-intl';

export const scope = 'cms_products';

export default defineMessages({
  page_title: {
    id: `${scope}.page_title`,
    defaultMessage: 'Proizvodii'
  },
  back: {
    id: `${scope}.back`,
    defaultMessage: 'Nazad'
  },
  authenticated_price: {
    id: `${scope}.authenticated_price`,
    defaultMessage: 'Ulogovana cena'
  },
  add_product: {
    id: `${scope}.add_product`,
    defaultMessage: 'Dodaj Proizvod'
  },
  add_value: {
    id: `${scope}.add_value`,
    defaultMessage: 'Dodaj Vrednost'
  },
  create_product: {
    id: `${scope}.create_product`,
    defaultMessage: 'Napravi Proizvod'
  },
  create_action: {
    id: `${scope}.create_action`,
    defaultMessage: 'Napravi akciju'
  },
  edit_action: {
    id: `${scope}.edit_action`,
    defaultMessage: 'Izmeni akciju'
  },
  delete_action: {
    id: `${scope}.delete_action`,
    defaultMessage: 'Obriši akciju'
  },
  check: {
    id: `${scope}.check`,
    defaultMessage: 'Čekiraj'
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Šifra'
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Kategorija'
  },
  sku: {
    id: `${scope}.sku`,
    defaultMessage: 'Sku'
  },
  choose_brand: {
    id: `${scope}.choose_brand`,
    defaultMessage: 'Izaberi brend'
  },
  
  choose_manufacturer: {
    id: `${scope}.choose_manufacturer`,
    defaultMessage: 'Izaberi proizvođača'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Naziv'
  },
  entry_price: {
    id: `${scope}.entry_price`,
    defaultMessage: 'Ulazna cena'
  },
  web_price: {
    id: `${scope}.web_price`,
    defaultMessage: 'Web cena'
  },
  retail_price: {
    id: `${scope}.retail_price`,
    defaultMessage: 'MP cena'
  },
  dealer_price: {
    id: `${scope}.dealer_price`,
    defaultMessage: 'Dil. cena'
  },
  push_from: {
    id: `${scope}.push_from`,
    defaultMessage: 'Vuče od'
  },
  add_discount_price: {
    id: `${scope}.add_discount_price`,
    defaultMessage: 'Dodaj akcijsku cenu'
  },
  add_discount_title: {
    id: `${scope}.add_discount_title`,
    defaultMessage: 'Dodaj popust izabranim proizvodima'
  },
  discount_type: {
    id: `${scope}.discount_type`,
    defaultMessage: 'Tip Akcije'
  },
  discount_price: {
    id: `${scope}.discount_price`,
    defaultMessage: 'Akcijska Cena'
  },
  discount_price_percentage: {
    id: `${scope}.discount_price_percentage`,
    defaultMessage: 'Procenat'
  },
  discount_from: {
    id: `${scope}.discount_from`,
    defaultMessage: 'Akcija važi od'
  },
  discount_to: {
    id: `${scope}.discount_to`,
    defaultMessage: 'Akcija važi do'
  },
  valid_from: {
    id: `${scope}.valid_from`,
    defaultMessage: 'Važi od'
  },
  valid_to: {
    id: `${scope}.valid_to`,
    defaultMessage: 'Važi do'
  },
  discounted_price: {
    id: `${scope}.discounted_price`,
    defaultMessage: 'Akc. cena'
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Aktivan'
  },
  action: {
    id: `${scope}.action`,
    defaultMessage: 'Akcije'
  },
  deleted_message: {
    id: `${scope}.deleted_message`,
    defaultMessage: 'Proizvod je uspešno izbrisan.'
  },
  updated_message: {
    id: `${scope}.updated_message`,
    defaultMessage: 'Proizvod je uspešno izmenjen.'
  },
  discount_updated_message: {
    id: `${scope}.discount_updated_message`,
    defaultMessage: 'Akcija je uspešno izmenjena.'
  },
  created_message: {
    id: `${scope}.created_message`,
    defaultMessage: 'Proizvod je uspešno kreiran.'
  },
  loader: {
    id: `${scope}.loader`,
    defaultMessage: 'Molimo vas sačekajte.'
  },
  brand: {
    id: `${scope}.brand`,
    defaultMessage: 'Brend'
  },
  supplier: {
    id: `${scope}.supplier`,
    defaultMessage: 'Dobavljač'
  },
  brand: {
    id: `${scope}.brand`,
    defaultMessage: 'Brend'
  },
  suppliers: {
    id: `${scope}.suppliers`,
    defaultMessage: 'Dobavljači'
  },
  brands: {
    id: `${scope}.brands`,
    defaultMessage: 'Brendovi'
  },
  
  manufacturer: {
    id: `${scope}.manufacturer`,
    defaultMessage: 'Proizvođač'
  },
  manufacturers: {
    id: `${scope}.manufacturers`,
    defaultMessage: 'Proizvođači'
  },
  choose_supplier: {
    id: `${scope}.choose_supplier`,
    defaultMessage: 'Izaberi Dobavljača'
  },
  cateogires: {
    id: `${scope}.cateogires`,
    defaultMessage: 'Kategorija'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Opis'
  },
  discount_success_created: {
    id: `${scope}.discount_success_created`,
    defaultMessage: 'Uspešno ste dodali akciju.'
  },
  deleted_discount_message: {
    id: `${scope}.deleted_discount_message`,
    defaultMessage: 'Uspešno ste izbrisali akciju.'
  },
  success_added_attribute: {
    id: `${scope}.success_added_attribute`,
    defaultMessage: 'Uspešno ste dodali atribut.'
  },
  success_updated_attribute: {
    id: `${scope}.success_updated_attribute`,
    defaultMessage: 'Uspešno ste izmenili atribut.'
  },
  success_deleted_attribute: {
    id: `${scope}.success_deleted_attribute`,
    defaultMessage: 'Uspešno ste izbrisali atribut.'
  },
  updated_desc: {
    id: `${scope}.updated_desc`,
    defaultMessage: 'Uspešno ste promenili opise izabranim proizvodima.'
  },
  update_attribute_status: {
    id: `${scope}.update_attribute_status`,
    defaultMessage: 'Uspešno ste promenili status atributa.'
  },
  update_attribute_values: {
    id: `${scope}.update_attribute_values`,
    defaultMessage: 'Uspešno ste promenili aktivnu vrednost atributa.'
  },
  create_auto_linking: {
    id: `${scope}.create_auto_linking`,
    defaultMessage: 'Uspešno ste napravili i povezali proizvod.'
  },
  created_attr_value: {
    id: `${scope}.created_attr_value`,
    defaultMessage: 'Uspešno ste dodali vrednost atributu.'
  },
  created_attribute: {
    id: `${scope}.created_attribute`,
    defaultMessage: 'Uspešno ste napravili atribut.'
  },
  add_brand: {
    id: `${scope}.add_brand`,
    defaultMessage: 'Dodaj Brend'
  },
  created_brand: {
    id: `${scope}.created_brand`,
    defaultMessage: 'Uspešno ste napravili brend.'
  },
  mass_attr_validation: {
    id: `${scope}.mass_attr_validation`,
    defaultMessage:
      'Molimo vas da prvo izaberete proizvode koje želite da menjate i izaberete kategoriju'
  },
  mass_desc_validation: {
    id: `${scope}.mass_desc_validation`,
    defaultMessage:
      'Molimo vas da prvo izaberete proizvode koje želite da menjate'
  },
  is_visible: {
    id: `${scope}.is_visible`,
    defaultMessage: 'Vidljiv na sajtu'
  },
  is_visible_true: {
    id: `${scope}.is_visible_true`,
    defaultMessage: 'Da'
  },
  is_visible_false: {
    id: `${scope}.is_visible_false`,
    defaultMessage: 'Ne'
  },
  is_active: {
    id: `${scope}.is_active`,
    defaultMessage: 'Status proizvoda'
  },
  is_active_true: {
    id: `${scope}.is_active_true`,
    defaultMessage: 'Status je aktivan'
  },
  is_active_false: {
    id: `${scope}.is_active_false`,
    defaultMessage: 'Status je neaktivan'
  },
  is_linking: {
    id: `${scope}.is_linking`,
    defaultMessage: 'Povezan sa'
  },
  is_not_linking: {
    id: `${scope}.is_not_linking`,
    defaultMessage: 'Nije povezan'
  },
  edit_product: {
    id: `${scope}.edit_product`,
    defaultMessage: 'Izmeni'
  },
  edit_product_title: {
    id: `${scope}.edit_product_title`,
    defaultMessage: 'Izmeni proizvod'
  },
  choose_main_photo: {
    id: `${scope}.choose_main_photo`,
    defaultMessage: 'Izaberi glavnu sliku'
  },
  choose_photos: {
    id: `${scope}.choose_photos`,
    defaultMessage: 'Izaberi slike'
  },
  photo_size_limit: {
    id: `${scope}.photo_size_limit`,
    defaultMessage: 'Maksimalni limit slike je 2MB'
  },
  photos_length_size: {
    id: `${scope}.photos_length_size`,
    defaultMessage: 'Možete dodati najviše 3 slike'
  },
  uploaded_main_image: {
    id: `${scope}.uploaded_main_image`,
    defaultMessage: 'Uspešno ste dodali glavnu sliku'
  },
  uploaded_more_images: {
    id: `${scope}.uploaded_more_images`,
    defaultMessage: 'Uspešno ste dodali slike'
  },
  removed_photo: {
    id: `${scope}.removed_photo`,
    defaultMessage: 'Uspešno ste obrisali sliku'
  },
  form_nav_tab1: {
    id: `${scope}.form_nav_tab1`,
    defaultMessage: 'Podaci'
  },
  form_nav_tab2: {
    id: `${scope}.form_nav_tab2`,
    defaultMessage: 'Akcije'
  },
  form_nav_tab3: {
    id: `${scope}.form_nav_tab3`,
    defaultMessage: 'Atributi'
  },
  form_nav_tab4: {
    id: `${scope}.form_nav_tab4`,
    defaultMessage: 'Slike'
  },
  form_nav_tab5: {
    id: `${scope}.form_nav_tab5`,
    defaultMessage: 'Stiker'
  },
  form_nav_tab6: {
    id: `${scope}.form_nav_tab6`,
    defaultMessage: 'Katalog stiker'
  },
  yup_validate_sku_max: {
    id: `${scope}.yup_validate_sku_max`,
    defaultMessage: 'Sku može sadržati najviše 15 karaktera'
  },
  yup_validate_sku_required: {
    id: `${scope}.yup_validate_sku_required`,
    defaultMessage: 'Sku je obavezan'
  },
  yup_validate_name_min: {
    id: `${scope}.yup_validate_name_min`,
    defaultMessage: 'Naziv mora sadržati najmanje 2 karaktera'
  },
  yup_validate_name_max: {
    id: `${scope}.yup_validate_name_max`,
    defaultMessage: 'Naziv može sadržati najviše 1200 karaktera'
  },
  yup_validate_name_required: {
    id: `${scope}.yup_validate_name_required`,
    defaultMessage: 'Naziv je obavezan'
  },
  yup_validate_entry_price_min: {
    id: `${scope}.yup_validate_entry_price_min`,
    defaultMessage: 'Ulazna cena mora biti veća od 0'
  },
  yup_validate_entry_price_required: {
    id: `${scope}.yup_validate_entry_price_required`,
    defaultMessage: 'Ulazna cena je obavezna'
  },
  yup_validate_description_min: {
    id: `${scope}.yup_validate_description_min`,
    defaultMessage: 'Opis mora sadržati najmanje 2 karaktera'
  },
  yup_validate_description_max: {
    id: `${scope}.yup_validate_description_max`,
    defaultMessage: 'Opis može sadržati najviše 1200 karaktera'
  },
  yup_validate_categories_required: {
    id: `${scope}.yup_validate_categories_required`,
    defaultMessage: 'Kategorije su obavezne'
  },
  yup_validate_discount_price_min: {
    id: `${scope}.yup_validate_discount_price_min`,
    defaultMessage: 'Akcijska cena mora biti veća od 0.01'
  },
  yup_validate_discount_type_required: {
    id: `${scope}.yup_validate_discount_type_required`,
    defaultMessage: 'Tip akcije je obavezan'
  },
  yup_validate_discount_price_required: {
    id: `${scope}.yup_validate_discount_price_required`,
    defaultMessage: 'Akcijska cena je obavezna'
  },
  yup_validate_discount_from_required: {
    id: `${scope}.yup_validate_discount_from_required`,
    defaultMessage: 'Početak akcije je obavezan'
  },
  yup_validate_discount_to_required: {
    id: `${scope}.yup_validate_discount_to_required`,
    defaultMessage: 'Završetak akcije je obavezan'
  },
  yup_validate_attribute_value_id_required: {
    id: `${scope}.yup_validate_attribute_value_id_required`,
    defaultMessage: 'Vrednost atributa je obavezan'
  },
  yup_validate_attribute_value_min: {
    id: `${scope}.yup_validate_attribute_value_min`,
    defaultMessage: 'Vrednost mora sadržati najmanje 1 karakter'
  },
  yup_validate_attribute_value_max: {
    id: `${scope}.yup_validate_attribute_value_max`,
    defaultMessage: 'Vrednost može sadržati najviše 255 karaktera'
  },
  yup_validate_attribute_value_required: {
    id: `${scope}.yup_validate_attribute_value_required`,
    defaultMessage: 'Polje je obavezno'
  },
  attribute_name: {
    id: `${scope}.attribute_name`,
    defaultMessage: 'Ime Atributa'
  },
  order: {
    id: `${scope}.order`,
    defaultMessage: 'Redosled'
  },
  create: {
    id: `${scope}.create`,
    defaultMessage: 'Dodaj'
  },
  value: {
    id: `${scope}.value`,
    defaultMessage: 'Vrednost'
  },
  add_description: {
    id: `${scope}.add_description`,
    defaultMessage: 'Dodaj opis'
  },
  add_attribute: {
    id: `${scope}.add_attribute`,
    defaultMessage: 'Dodaj atribut'
  },
  add_attributes: {
    id: `${scope}.add_attributes`,
    defaultMessage: 'Dodaj atribute'
  },
  give_up: {
    id: `${scope}.give_up`,
    defaultMessage: 'Odustani'
  },
  change_description: {
    id: `${scope}.change_description`,
    defaultMessage: 'Promenite opis izabranim proizvodima'
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Zatvori'
  },
  attribute: {
    id: `${scope}.attribute`,
    defaultMessage: 'Atributi'
  },
  with_attribute: {
    id: `${scope}.with_attribute`,
    defaultMessage: 'Sa atributima'
  },
  without_attribute: {
    id: `${scope}.without_attribute`,
    defaultMessage: 'Bez atributa'
  },
  remove: {
    id: `${scope}.remove`,
    defaultMessage: 'Obriši'
  },
  apply: {
    id: `${scope}.apply`,
    defaultMessage: 'Primeni'
  },
  choose_category: {
    id: `${scope}.choose_category`,
    defaultMessage: 'Izaberi Kategoriju'
  },
  all_cateogires: {
    id: `${scope}.all_cateogires`,
    defaultMessage: 'Kategorije'
  },
  search_placeholder: {
    id: `${scope}.search_placeholder`,
    defaultMessage: 'Pretraži'
  },
  table_no_result: {
    id: `${scope}.table_no_result`,
    defaultMessage: 'Nema rezultata.'
  },
  no_result: {
    id: `${scope}.no_result`,
    defaultMessage: 'Nema rezultata o pretragi.'
  },
  discounts: {
    id: `${scope}.discounts`,
    defaultMessage: 'Popusti'
  },
  included: {
    id: `${scope}.included`,
    defaultMessage: 'Uključeni'
  },
  connected_products: {
    id: `${scope}.connected_products`,
    defaultMessage: 'Povezani proizvodi'
  },
  connected: {
    id: `${scope}.connected`,
    defaultMessage: 'Povezani'
  },
  disconnected: {
    id: `${scope}.disconnected`,
    defaultMessage: 'Nepovezani'
  },
  shown: {
    id: `${scope}.shown`,
    defaultMessage: 'Prikazani'
  },
  not_shown: {
    id: `${scope}.not_shown`,
    defaultMessage: 'Neprikazani'
  },
  visible_on_site: {
    id: `${scope}.visible_on_site`,
    defaultMessage: 'Prikazani na sajtu'
  },
  photo_in_products: {
    id: `${scope}.photo_in_products`,
    defaultMessage: 'Slike u proizvodima'
  },
  with_photos: {
    id: `${scope}.with_photos`,
    defaultMessage: 'Sa slikom'
  },
  without_photos: {
    id: `${scope}.without_photos`,
    defaultMessage: 'Bez slike'
  },
  price_from: {
    id: `${scope}.price_from`,
    defaultMessage: 'Cena od'
  },
  from: {
    id: `${scope}.from`,
    defaultMessage: 'Od'
  },
  price_to: {
    id: `${scope}.price_to`,
    defaultMessage: 'Cena do'
  },
  to: {
    id: `${scope}.to`,
    defaultMessage: 'Do'
  },
  growing: {
    id: `${scope}.growing`,
    defaultMessage: 'Rastuća'
  },
  descending: {
    id: `${scope}.descending`,
    defaultMessage: 'Padajuća'
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status'
  },
  filter_active: {
    id: `${scope}.filter_active`,
    defaultMessage: 'Aktivni'
  },
  filter_inactive: {
    id: `${scope}.filter_inactive`,
    defaultMessage: 'Nekativni'
  },
  filter_prev_status_active: {
    id: `${scope}.filter_prev_status_active`,
    defaultMessage: 'Status: Aktivni'
  },
  filter_prev_status_inactive: {
    id: `${scope}.filter_prev_status_inactive`,
    defaultMessage: 'Status: Neaktivni'
  },
  filter_prev_with_image: {
    id: `${scope}.filter_prev_with_image`,
    defaultMessage: 'Proizvodi: Sa slikom'
  },
  filter_prev_without_image: {
    id: `${scope}.filter_prev_without_image`,
    defaultMessage: 'Proizvodi: Bez slika'
  },
  filter_prev_with_attributes: {
    id: `${scope}.filter_prev_with_attributes`,
    defaultMessage: 'Proizvodi: Sa Atributima'
  },
  filter_prev_without_attributes: {
    id: `${scope}.filter_prev_without_attributes`,
    defaultMessage: 'Proizvodi: Bez Atributa'
  },
  filter_prev_with_connected: {
    id: `${scope}.filter_prev_with_connected`,
    defaultMessage: 'Proizvodi: Konektovani'
  },
  filter_prev_without_disconnected: {
    id: `${scope}.filter_prev_without_disconnected`,
    defaultMessage: 'Proizvodi: Nekonektovani'
  },
  filter_prev_live: {
    id: `${scope}.filter_prev_live`,
    defaultMessage: 'Proizvodi: Prikazani na sajtu'
  },
  filter_prev_dead: {
    id: `${scope}.filter_prev_dead`,
    defaultMessage: 'Proizvodi: Neprikazani na sajtu'
  },
  filter_prev_supplier: {
    id: `${scope}.filter_prev_supplier`,
    defaultMessage: 'Dobavljač: '
  },
  filter_prev_category: {
    id: `${scope}.filter_prev_category`,
    defaultMessage: 'Kategorija: '
  },
  filter_prev_web_price_from: {
    id: `${scope}.filter_prev_web_price_from`,
    defaultMessage: 'Web cena od: '
  },
  filter_prev_web_price_to: {
    id: `${scope}.filter_prev_web_price_to`,
    defaultMessage: 'Web cena do: '
  },
  filter_prev_entry_price_from: {
    id: `${scope}.filter_prev_entry_price_from`,
    defaultMessage: 'Ulazna cena od: '
  },
  filter_prev_entry_price_to: {
    id: `${scope}.filter_prev_entry_price_to`,
    defaultMessage: 'Ulazna cena do: '
  },
  filter_prev_included_discount: {
    id: `${scope}.filter_prev_included_discount`,
    defaultMessage: 'Uključeni popusti'
  },
  filter_prev_discount_price_from: {
    id: `${scope}.filter_prev_discount_price_from`,
    defaultMessage: 'Cena popusta od: '
  },
  filter_prev_discount_price_to: {
    id: `${scope}.filter_prev_discount_price_to`,
    defaultMessage: 'Cena popusta do: '
  },
  filter_prev_discount_from: {
    id: `${scope}.filter_prev_discount_from`,
    defaultMessage: 'Datum popusta od: '
  },
  filter_prev_discount_to: {
    id: `${scope}.filter_prev_discount_to`,
    defaultMessage: 'Datum popusta do: '
  },
  product404: {
    id: `${scope}.product404`,
    defaultMessage: 'Proizvod ne postoji ili je izbrisan.'
  },
  success_disconnected: {
    id: `${scope}.success_disconnected`,
    defaultMessage: 'Uspešno ste razdvojili proizvod'
  },
  alert_title: {
    id: `${scope}.alert_title`,
    defaultMessage: 'Prvo izaberite vrednost!'
  },
  pass_products: {
    id: `${scope}.pass_products`,
    defaultMessage: 'Prebaci proizvode'
  },
  add_sticker: {
    id: `${scope}.add_sticker`,
    defaultMessage: 'Dodaj sticker'
  },
  
  add_action: {
    id: `${scope}.add_action`,
    defaultMessage: 'Dodaj nalepnicu'
  },
  attributes_missing: {
    id: `${scope}.attributes_missing`,
    defaultMessage: 'Atributi koji nedostaju su:'
  },
  auto: {
    id: `${scope}.auto`,
    defaultMessage: 'Automatski'
  },
  create_and_activate_attirbutes: {
    id: `${scope}.create_and_activate_attirbutes`,
    defaultMessage: 'Kreiraj atribute i aktiviraj ih'
  },
  create_and_deactivate_attirbutes: {
    id: `${scope}.create_and_deactivate_attirbutes`,
    defaultMessage: 'Kreiraj atribute i deaktiviraj ih'
  },
  country_of_origin: {
    id: `${scope}.country_of_origin`,
    defaultMessage: 'Zemlja porekla'
  }
});
